<template>
	<main>
		<!-- breadcrumb-area -->
		<section>
			<img src="assets/img/bg/freightman.jpg" class="img-fluid" />
		</section>

		<section class="about-area pb-60 pt-100">
			<div class="container">
				<!-- START TABS DIV -->
				<div class="tabbable-responsive">
					<div class="tabbable">
						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item">
								<a
									class="nav-link active"
									id="first-tab"
									data-toggle="tab"
									href="#first"
									role="tab"
									aria-controls="first"
									aria-selected="true"
									>Features</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="second-tab"
									data-toggle="tab"
									href="#second"
									role="tab"
									aria-controls="second"
									aria-selected="false"
									>Integrations</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="third-tab"
									data-toggle="tab"
									href="#third"
									role="tab"
									aria-controls="third"
									aria-selected="false"
									>Live Demo</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="fourth-tab"
									data-toggle="tab"
									href="#fourth"
									role="tab"
									aria-controls="fourth"
									aria-selected="false"
									>Customers</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="fifth-tab"
									data-toggle="tab"
									href="#fifth"
									role="tab"
									aria-controls="fifth"
									aria-selected="false"
									>Pricing</a
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="tab-content">
					<div
						class="tab-pane fade show active"
						id="first"
						role="tabpanel"
						aria-labelledby="first-tab"
					>
						<h6 class="pt-15"><b>Main features</b></h6>
						<br />
						<p>
							Technology investments are proven as the smartest way; to reduce
							high fixed costs and tight margins associated with transport
							business, deliver better services and provide solid platform for
							business growth.
						</p>
						<p>
							FreightMan is windows based solution, for transport companies,
							which integrates with all the standard Microsoft tools. A uniform
							navigation is familiar and easier to learn. The system is highly
							configurable to provide flexibility and at the same time can also
							be tailored to meet your precise needs. We have served customers
							from every segment of the industry and range from small operators
							to those with hundreds and thousands of fleets; including vehicle
							owners, transport contractors and commission agents.
						</p>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Contacts Management</h4>
										</div>
										<div class="about-content pl-30 pt-10">
											<ul class="feature-content">
												<li>
													Create and manage contact details for consignor,
													consignee, commission agent, fuel pumps,
													driver/cleaners etc.
												</li>
												<li>
													**Optionally send event driven SMS automatically; i.e.
													FreightMan can be configured to automatically send SMS
													to respective contact on events such as billing,
													payment receipt, delivery etc.
												</li>
												<li>
													Manage multiple billing/delivery addresses for
													consignees and billing parties.
												</li>
												<li>
													Attach scanned copies of Driving license, Photo
													identity proof etc. with Driver/Cleaner contacts.
												</li>
												<li>
													Merge duplicate contacts.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-lg-6 col-sm-12 col-xs-12">
										<div class="contact_management_slider">
											<div class="inner_slide_1">
												<img
													src="assets/img/products/Multiple-Addresses-1000x750.png"
													class="img-fluid"
												/>
											</div>
											<div class="inner_slide_1">
												<img
													src="assets/img/products/Merge-Contacts-1000x750.png"
													class="img-fluid"
												/>
											</div>
											<div class="inner_slide_1">
												<img
													src="assets/img/products/Create-and-Manage-1000x750.png"
													class="img-fluid"
												/>
											</div>
											<div class="inner_slide_1">
												<img
													src="assets/img/products/Attachments-1000x750.png"
													class="img-fluid"
												/>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-lg-6 col-sm-12 col-xs-12">
										<div class="cost_management_slider">
											<div class="inner_slide_2">
												<img
													src="assets/img/products/Fuel-Consumption-and-Mileage-980x760.png"
													class="img-fluid"
												/>
											</div>
											<div class="inner_slide_2">
												<img
													src="assets/img/products/Expenses-incurred-in-a-round-trip-980x760.png"
													class="img-fluid"
												/>
											</div>
											<div class="inner_slide_2">
												<img
													src="assets/img/products/Compare-vehicle-income-with-expenses-incurred-980x760.png"
													class="img-fluid"
												/>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Cost Management</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<strong
													>Costs and challenges for running transport business
													increases by each passing day. To run transport
													operations efficiently, we have designed it to
													maximize operational efficiencies and minimize cost to
													help your business grow.</strong
												>
											</p>

											<ul class="feature-content pl-30 pt-30">
												<li>
													Book expenses incurred in a round trip including
													driver allowances.
												</li>
												<li>
													Compare cash advance with expenses incurred and
													allocate balances to accounting ledgers.
												</li>
												<li>
													Calculate days spent to complete a round trip and
													compare them with revenues generated to get income per
													day.
												</li>
												<li>
													Provide opening and closing odometer reading and get
													mileage returned by the vehicle to complete a round
													trip.
												</li>
												<li>
													##Get cost managed by categories like diesel, tyres,
													lubricants, repairs etc. and compare them with
													revenues generated by each vehicle to get
													profitability.
												</li>
												<li>
													##Get vehicle expenses based on spare parts and thus
													monitor aging vehicles.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Trip Management</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<b
													>FreightMan’s easier Trip Management and flexible
													navigation lets you generate trips in a brisk. Its
													integration across all modules provides a complete and
													comprehensive coverage of all aspects from accounts,
													through transport and fleet management, scheduling to
													route optimization.</b
												>
											</p>

											<ul class="feature-content pl-30 pt-30">
												<li>
													Create and manage trips for each and every vehicle; or
													easily import them, from Microsoft excel worksheet,
													directly to FreightMan.
												</li>
												<li>
													**Create trip challans, Print Bilty or Freight Voucher
													from trips automatically.
												</li>
												<li>
													Automatic allocation of cash and diesel advance to
													accounting ledgers
												</li>
												<li>
													Option to mention cash or diesel advance received from
													billing party.
												</li>
												<li>
													Option to allocate expenses, other than freight, that
													can be billed to billing party or paid to vehicle
													owner/commission agent.
												</li>
												<li>
													Create up to 10 customized fields for additional
													information to meet your requirement.
												</li>
												<li>
													Manage shortage and detentions.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/TripManagement-2.jpg"
											alt="TripManagement"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/freightinvoice-2.jpg"
											alt="FreightInvoice"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Generate professional freight invoices</h4>
										</div>
										<div class="about-content pt-10">
											<ul class="feature-content pl-30">
												<li>
													Multiple options to filter trips for freight invoice
													generation.
												</li>
												<li>
													Create freight invoice with detention charges or
													generate a separate invoice for detention charges.
												</li>
												<li>
													Create freight invoice with expenses incurred on
													behalf of party or create separate debit note for the
													same.
												</li>
												<li>
													Choose invoice format from wide range of pre-installed
													templates or let us create one as per your
													specification and print them on plain, headed or
													pre-printed paper.
												</li>
												<li>
													**Supports multiple formats for invoice printing.
												</li>
												<li>
													Get complete control over invoice nos. i.e. either
													same sequence can be allocated to each invoice or
													parallel sequence can be generated for different
													invoices.
												</li>
												<li>
													FreightMan automatically ensures each trip is only
													billed once.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Hired vehicle / Subcontractor Management</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<b
													>Costs and calculations for both hired purchase and
													lease expenses for vehicles are performed
													automatically based on data retrieved from different
													modules. Automation of such operations minimizes risk
													of human errors.</b
												>
											</p>

											<ul class="feature-content pt-30 pl-30">
												<li>
													The system automatically calculates when and how much
													a contractor should be paid and generates Freight
													Statement based on the same.
												</li>
												<li>
													Software automatically manages deductions such as
													shortage, cash and diesel advance, TDS etc., and
													ensures each trip is only paid once.
												</li>
												<li>
													There is no need for the subcontractor to invoice your
													company.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/hiredtruck.jpg"
											alt="FreightInvoice"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/accounting-2.jpg"
											alt="FreightInvoice"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Financial Accounting</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<b
													>FreightMan covers all the accounting and financial
													requirements of a transport business. You will love
													ability to drill into original transaction whenever it
													occurs without having to navigate in and out of
													modules.</b
												>
											</p>

											<ul class="feature-content pt-30 pl-30">
												<li>
													Manage receipts, payments, journals and contras.
												</li>
												<li>
													Manage debit notes and credit notes.
												</li>
												<li>
													Manage cash and fund flow.
												</li>
												<li>
													Manage bills receivables and bills payables.
												</li>
												<li>
													Bank reconciliation.
												</li>
												<li>
													Voucher Printing
												</li>
												<li>
													**Cheque Printing
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Get reminded anytime</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<b
													>Have you ever missed a date? With FreightMan, you can
													be left assured that you will never miss important
													things related to maintenance of your vehicles or
													other things which are required to be performed on
													time.</b
												>
											</p>

											<ul class="feature-content pt-30 pl-30">
												<li>
													Set due dates and reminders for important events such
													as vehicle insurance, PUC, instalment and others.
												</li>
												<li>
													Set due dates and reminders for Driving or HazChem
													license expiry of drivers.
												</li>
												<li>
													##Get reminded on vehicle maintenance events, such as
													Wheel Greasing and Oil Change.
												</li>
												<li>
													Get reminded on trips pending to be billed.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/remind-2.jpg"
											alt="FreightInvoice"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/workshop-2.jpg"
											alt="FreightInvoice"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Workshop Management</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<b
													>FreightMan helps you manage workshop and maintenance
													operations to ensure you meet the regulatory and
													safety standards for all vehicles in the most cost
													efficient and well planned way.</b
												>
											</p>

											<ul class="feature-content pt-30 pl-30">
												<li>
													Track maintenance history of each and every vehicle
													and generate detailed reports with accurate costs.
												</li>
												<li>
													System predicts when the maintenance needs to be
													carried out based on odometer or date information from
													vehicle’s master data.
												</li>
												<li>
													A fully integrated stock control system keeps parts
													and individual stock records; including stock of old
													tyres.
												</li>
												<li>
													Reduce administrative cost to manage compliance.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Supply Management</h4>
										</div>
										<div class="about-content pt-10">
											<ul class="feature-content pl-30">
												<li>
													Get reports based on parts, diesel and tyres
													purchased, filter by date, supplier and item name..
												</li>
												<li>
													Compare rates for individual items to get vendor with
													the lowest rates.
												</li>
												<li>
													**Generate purchase order using purchase order builder
													so that software help you generate purchase order
													based on recent purchase history with lowest rates.
												</li>
												<li>
													Automatically match invoices with delivery notes.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/supply.jpg"
											alt="FreightInvoice"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/insights.jpg"
											alt="FreightInvoice"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Get insights from your business</h4>
										</div>
										<div class="about-content">
											<p class="pt-15">
												<b
													>The FreightMan reporting suite includes wide range of
													reports that help you set real time goals and track
													the right metrics. It helps you assess, compare,
													summarize and scrutinize performance and gives you a
													quick view of the most critical data.</b
												>
											</p>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40 pt-50">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-12 col-lg-12 col-xs-12">
										<div class="section-title pb-15">
											<h4>Trip Reports</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Get reports on trip history with loading and unloading
													details, cash and diesel advances, shortage,
													detention, outward bill details, inward bill details
													and more. Which can be filtered by virtually anything
													that you can think of, be it consignor, consignee,
													subcontractor, vehicle no, product, loading station,
													unloading station to name few.
												</li>
												<li>
													Trip Sundries let you figure out cost centres
													allocated to various trips which can be categorized in
													inward or outward receivables as well as outward
													deductibles.
												</li>
												<li>
													Diesel register lets you assess diesel consumption for
													a trip which can be assessed based on pump, vehicle,
													date and subcontractor.
												</li>
												<li>
													**Delivery Order Details lets you assess status of
													delivery order and trips performed against each
													delivery order with balance quantity.
												</li>
												<li>
													Shortage register lets you assess trips with shortage,
													which can be filtered by vehicle no. date, party,
													subcontractor, loading and unloading station.
												</li>
												<li>
													Un-billed trips generate a report on trips that are
													pending either to be inward or outward billing.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div class="row pt-40">
									<div class="col-lg-6 col-md-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Comparison Reports</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Compare income as against expenses incurred during a
													trip in trip-wise income register.
												</li>
												<li>
													##Vehicle Statement lets you compare revenues
													generated by a vehicle during a said period as against
													expenses incurred through Trip Expense, Fuel
													consumption, Tyres, Spares, Repairs and Lubricants, to
													identify profitable and loss making vehicles.
												</li>
												<li>
													Comparison report lets you compare income generated
													from a hired vehicle as against expenses incurred in
													hiring, to calculate commission income.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-lg-6 col-md-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Summary Reports</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Get summary reports on delivery orders, Trips,
													Commission Income and Freight Statement to name few.
												</li>
												<li>
													##Vehicle revenues, costs and profits down to per
													kilometre level.
												</li>
												<li>
													Reports on profitable routes down to per kilometre
													level.
												</li>
												<li>
													##Comprehensive reporting on the profitability of each
													and every vehicle and vehicle groups.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div class="row pt-40">
									<div class="col-lg-6 col-md-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Financial Reports</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Reports on Freight Invoices, Freight Statements,
													Receipts, Payments, Journals and Contras.
												</li>
												<li>
													Day Book, Cash/Bank Book, Ledger Book, Ledger Book
													(Bill-wise) and Ledger Vouchers.
												</li>
												<li>
													Purchase and Sales bills pending
												</li>
												<li>
													Outstanding receivables and payables.
												</li>
												<li>
													Balance Sheet
												</li>
												<li>
													Profit & Loss account
												</li>
												<li>
													Trial Balance
												</li>
												<li>
													Outstanding statements
												</li>
											</ul>
										</div>
									</div>
									<div class="col-lg-6 col-md-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Inventory Reports##</h4>
										</div>
										<div class="about-content">
											<ul class="feature-content pl-30">
												<li>
													Reports on Stock Summary, Movement and vouchers.
												</li>
												<li>Stock Inward and Outward Registers.</li>
												<li>Tyre consumption report</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Secure and accessible</h4>
										</div>
										<div class="about-content pt-10">
											<ul class="feature-content pl-30">
												<li>
													Role based profiles can be used to secure and simplify
													process, features and functions for each group of
													employees.
												</li>
												<li>
													System automatically saves information such as who
													created, modified or accessed which entry, at what
													time and from which computer
												</li>
												<li>
													You can also track which entry was deleted by whom, at
													what time and from which computer.
												</li>
												<li>
													**Online connectivity provides real time low cost
													access to information across all parts of your
													business.
												</li>
												<li>
													**Offline connectivity provides the cheapest and
													almost real time access to information across all
													parts of your business.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/secure-2.jpg"
											alt="FreightInvoice"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div
							class="content_wrap mt-40"
							style="background-image:url(assets/img/bg/footer-bg-4.jpg);background-repeat: no-repeat;background-size: cover;"
						>
							<section class="about-area speial-feature">
								<div class="row">
									<div class="col-xl-12 col-lg-12 col-xs-12">
										<div class="section-title pb-5">
											<h3>Special Features</h3>
										</div>
										<div class="about-content">
											<p>
												<i class="fa fa-check pr-10" aria-hidden="true"></i
												>Fully customizable to meet your requirement
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Multi company generation
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Multi user
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>SMS integration
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Option for multi site online synchronization
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Option for multi site offline synchronization
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Voucher and Report level user based security
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Backup and Restore companies
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Tally Integration
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Directly open vouchers from reports
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Print, Email or Export vouchers and reports
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Email or Export vouchers and reports in various file
												formats such as PDF, Doc, Docx, Xls, Xlsx etc.
											</p>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<div class="servicee-sidebar mb-50">
								<div class="sidebar-link grey-bg">
									<h3>Excellent Software at Fair Prices</h3>
									<div class="about-content pt-10" style="padding: 25px;">
										<h6>
											<b
												>You pay only once for permanent use of your license!</b
											>
										</h6>

										<P
											><i
												class="fa fa-check-square-o pr-10 pt-30"
												aria-hidden="true"
											></i
											>FreightMan combines all the features required for a
											complete fleet management application in a single
											software.</P
										>

										<P
											><i
												class="fa fa-check-square-o pr-10 pt-15"
												aria-hidden="true"
											></i
											>And the best of all: A single purchase with permanent use
											and no update requirements</P
										>
										<br />

										<h5 class="pt-15">Investment Security</h5>
										<p>
											<i class="fa fa-check pr-10 pt-30" aria-hidden="true"></i
											>Lifetime licenses – use is possible without time
											restrictions.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>1 year free customer support.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>1 month free for customization.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>Telephonic and online consultation or support via
											competent employees.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>Easy and affordable post-warranty subscriptions
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--end of tab 1-->

					<div
						class="tab-pane fade"
						id="second"
						role="tabpanel"
						aria-labelledby="second-tab"
					>
						<h6 class="pt-15">
							<b>FreightMan works seemlessly with following services</b>
						</h6>
						<br />
						<p>
							<i class="fa fa-check pr-10" aria-hidden="true"></i
							><b>Tally accounting software</b>
						</p>
						<p>
							Import data from tally accounting software or export to the same
						</p>

						<p>
							<i class="fa fa-check pr-10" aria-hidden="true"></i
							><b>Microsoft Office</b>
						</p>
						<p>
							Import data from excel worksheet or export to word, excel, outlook
							express etc.
						</p>

						<p>
							<i class="fa fa-check pr-10" aria-hidden="true"></i
							><b>Acrobat Reader</b>
						</p>
						<p>Export data from FreightMan to create PDF files.</p>
					</div>
					<!--end of tab 2-->
					<div
						class="tab-pane fade"
						id="third"
						role="tabpanel"
						aria-labelledby="third-tab"
					>
						<p>
							If you want us to show you what our software can do for you, Tell
							us something about you and we will gladly arrange a live demo for
							you
						</p>
						<br />
						<div class="row">
							<div class="col-md-7">
								<form
									id="contact-form"
									action="#"
									class="contact-form"
									method="POST"
								>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<input name="name" type="text" placeholder="Name" />
										</div>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<input name="email" type="email" placeholder="Email" />
										</div>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<input
												name="contact"
												type="contact"
												placeholder="Contact No"
											/>
										</div>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<button class="btn" type="submit">Submit</button>
										</div>
									</div>
								</form>
								<p class="ajax-response"></p>
							</div>
						</div>
					</div>
					<!--end of tab 3-->
					<div
						class="tab-pane fade"
						id="fourth"
						role="tabpanel"
						aria-labelledby="fourth-tab"
					>
						<div class="content_wrap mt-10">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-12 col-lg-12">
										<section class="testimonial-area pt-100 pb-100">
											<div class="container">
												<div class="row">
													<div class="col-xl-12">
														<div class="testimonial-active owl-carousel">
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	We use FreightMan daily to manage our accounts
																	and operations. It's fast and intuitive and
																	does exactly what we need for our transport
																	company.
																</div>
																<h4>Rajmoti Road Movers, Kodinar, Gujarat</h4>
															</div>
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	FreightMan has changed the way we manage our
																	accounts and operations. This is exactly what
																	a great software can offer.
																</div>
																<h4>
																	Mahadev Transport Co., Sanchor, Rajasthan
																</h4>
															</div>
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	We are wondering how easy our job has become
																	after implementing FreightMan at our various
																	branch offices. We opted for offline
																	synchronization of branch offices with our
																	head office and it works seamlessly.
																</div>
																<h4>
																	Shree Rajshakti Logistics, Bhuj, Gujarat
																</h4>
															</div>
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	We opted for online version of FreightMan
																	connecting through our various offices all
																	over india, and we are very satisfied with the
																	way things have gone thus far.
																</div>
																<h4>
																	Shree Momay Transport, Jamnagar, Gujarat
																</h4>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
							</section>
						</div>
					</div>
					<!--end of tab 4-->
					<div
						class="tab-pane fade"
						id="fifth"
						role="tabpanel"
						aria-labelledby="fifth-tab"
					>
						<div class="content_wrap">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6">
										<h6><b>Our prizing includes free</b></h6>
										<br />
										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i
											>Personalized Training
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i
											>Customization for the first month of purchase
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i
											>Telephonic/Remote Support for the first year of purchase
										</p>
									</div>
									<div class="col-xl-6 col-lg-6">
										<h6><b>Ask for a Free Trial</b></h6>
										<br />
										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i>Get
											satisfied before buying
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i>Trial
											includes all the features mentioned herein
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i>You
											can retain your data and customization from trial at the
											time of purchase
										</p>
									</div>
								</div>

								<div class="row pt-30">
									<div class="col-xl-12 col-lg-12">
										<table class="table table-bordered table-striped">
											<tr>
												<td>Particulars</td>
												<td>Amount (INR)</td>
											</tr>

											<tr>
												<td>
													<b>Single user Accounts + Vehicle Maintenance</b
													><br /><br />(for lifetime use)
												</td>
												<td><b>35000/-</b> (per license)</td>
											</tr>

											<tr>
												<td>
													<b>Single user Accounts Only</b><br /><br />(for
													lifetime use)
												</td>
												<td><b>20000/-</b> (per license)</td>
											</tr>

											<tr>
												<td>
													<b>Single user Vehicle Maintenance Onl</b
													><br /><br />(for lifetime use)
												</td>
												<td><b>20000/-</b> (per license)</td>
											</tr>

											<tr>
												<td>
													<b>Add-on workstations (local)</b><br /><br />(for
													lifetime use)
												</td>
												<td><b>2000/-</b> (per workstation)</td>
											</tr>

											<tr>
												<td>
													<b>Add-on workstations (remote)</b><br /><br />(for
													lifetime use)
												</td>
												<td><b>5000/-</b> (per workstation)</td>
											</tr>
										</table>
									</div>
								</div>

								<div class="row pt-30">
									<div class="col-lg-12 col-md-12">
										<div class="section-title pb-15">
											<h5>Frequently asked Questions</h5>
										</div>
										<p><b>Q - How can I pay ?</b></p>
										<p>
											We are going to accept online payments very soon, for the
											mean time you can pay through a high value cheque or
											demand draft in favor of Extreme Solutions and payable at
											Gandhidham.
										</p>
										<p>
											You can also use RTGS or NEFT modes of payment, if you
											wish to.
										</p>
									</div>
								</div>

								<div class="row pt-30">
									<div class="col-lg-6 col-md-6 col-xs-12">
										<p><b>Q - Will I have to pay renewal charges ?</b></p>
										<p>No, our prizing are for lifetime use</p>

										<p><b>Q - What is included in the training ?</b></p>
										<p>
											A comprehensive and gradual training program will be held
											by our experienced team member to get you on board.
										</p>
										<p>
											You will get help with each and every module and will also
											get to know some hidden features as you get along.
										</p>

										<p><b>Q - What customization can I request ?</b></p>
										<p>
											Anything, that you find you need it your way, you can ask.
											Most of the customization for the first month of purchase
											will remain free.
										</p>

										<p>
											<b
												>Q - Can I request customization after first month of
												purchase ?</b
											>
										</p>
										<p>
											Yes, you can ask for customization at anytime, however, it
											will be at some prize after the first month of purchase.
										</p>
									</div>
									<div class="col-lg-6 col-md-6 col-xs-12">
										<p>
											<b
												>Q - What will be the charges for customization after
												first month of purchase ?</b
											>
										</p>
										<p>
											It depends on what kind of customization you are asking
											for. We will let you know estimated time and cost for the
											customization at the time of your request.
										</p>

										<p><b>Q - What is telephonic/remote support ?</b></p>
										<p>
											In case if you get stuck somewhere, You will be personally
											attended by one of our team member to guide you on
											telephone or assist you through remote desktop software.
										</p>

										<p>
											<b
												>Q - Will I get support after first year of purchase
												?</b
											>
										</p>
										<p>Yes, but at a prize.</p>

										<p>
											<b
												>Q - What will it cost for telephonic/remote support
												after first year of purchase ?</b
											>
										</p>
										<p>
											Our subscriptions are simple and easy to understand. You
											can buy our annual support contract and enjoy support for
											a year or you can buy support tickets (minimum 10), which
											can be used without any time restrictions.
										</p>
									</div>
								</div>
							</section>
						</div>
					</div>
					<!--end of tab 5-->
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
	name: "freightman",
	mounted() {
		// contact management slider
		$(".contact_management_slider").slick({
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: false,
						arrows: false,
						autoplay: true,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: false,
						arrows: false,
						autoplay: true,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: false,
						arrows: false,
						autoplay: true,
					},
				},
			],
		});

		// cost management slider
		$(".cost_management_slider").slick({
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: false,
						arrows: false,
						autoplay: true,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: false,
						arrows: false,
						autoplay: true,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: false,
						arrows: false,
						autoplay: true,
					},
				},
			],
		});
		// script for testimonials slider
		$(".testimonial-active").owlCarousel({
			loop: true,
			margin: 10,
			nav: false,
			dots: false,
			autoplay: true,
			responsive: {
				100: {
					items: 1,
				},
				200: {
					items: 1,
				},
				300: {
					items: 1,
				},
			},
		});
	},
};
</script>

<style></style>
